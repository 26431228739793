import mixins from '@/mixins'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import mainFilter from '../../components/filter/index.vue'
import { camelCase, debounce, snakeCase } from 'lodash'

export default {
  name: 'user-certificate-course',
  mixin: [mixins],
  data() {
    return {
      page: 1,
      currentUserCourse: null,
      showGenerateAllDialog: false,
      showGenerateUserDialog: false,
      showRemoveUserDialog: false,
      filter: ''
    }
  },
  components: {
    mainFilter
  },
  computed: {
    ...mapGetters({
      list: 'userCertificate/list',
      limit: 'userCertificate/limit',
      skip: 'userCertificate/skip',
      isListLoading: 'userCertificate/isListLoading',
      listLength: 'userCertificate/listLength',
      setting: 'courses/setting',
      simpleCoursesList: 'userCertificate/simpleList',
      filterCourseId: 'userCertificate/filterCourseId',
      isGenerateAllLoading: 'userCertificate/isGenerateAllLoading',
      isGenerateUserLoading: 'userCertificate/isGenerateUserLoading',
      isRemoveUserLoading: 'userCertificate/isRemoveUserLoading',
    }),
    headers() {
      return [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'User',
          value: 'user'
        },
        {
          text: 'Package',
          value: 'package'
        },
        {
          text: 'Points',
          value: 'points'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: 'Certificate',
          value: 'certificate',
          sortable: false
        },
        {
          text: 'Type',
          value: 'type',
          sortable: false
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    },
    getLocalStorageFilter() {
      return JSON.parse(localStorage.getItem('user_certificate_filters'))
    },
    getLocalStorageSearch() {
      return JSON.parse(localStorage.getItem('user_certificate_search'))
    },
    getLocalStorageFilterCourseId() {
      return localStorage.getItem('user_certificate_filter_course_id')
    }
  },
  created() {
    const courseId = +this.$route.params.courseId
    if (!!this.filterCourseId && this.filterCourseId !== courseId) {
      this.changeFilter()
    } else {
      this.getLocalStorageDataFilter()
    }

    if (!this.list.length) {
      this.fetchList(this.$route.params)
    }
    if (this.list) {
      if (this.skip !== 0) {
        this.page = Math.round(this.skip / this.limit) + 1
      }
    }
    this.courseList(this.$route.params)
  },
  methods: {
    ...mapActions({
      fetchList: 'userCertificate/GET_LIST',
      generateAll: 'userCertificate/GENERATE_ALL',
      generateUser: 'userCertificate/GENERATE_USER',
      removeUser: 'userCertificate/REMOVE_USER',
      courseList: 'userCertificate/COURSE_LIST',
    }),
    ...mapMutations({
      setListContent: 'userCertificate/SET_LIST',
      setList: 'userCertificate/SET_LIST',
      changeFixBody: 'system/FIX_BODY',
      changeSkip: 'userCertificate/CHANGE_SKIP',
      changeSort: 'userCertificate/CHANGE_SORT',
      changeFilter: 'userCertificate/CHANGE_FILTER',
      setFilterData: 'userCertificate/setFilterData',
      setFilterCourseId: 'userCertificate/setFilterCourseId'
    }),
    customSort(items, nameField, isDesc) {
      let sortData = {}

      if (nameField.length) {
        const toSnakeIndex = snakeCase(nameField[0])

        if (isDesc[0]) {
          sortData = {
            field: toSnakeIndex,
            direction: 'desc',
          }
        } else {
          sortData = {
            field: toSnakeIndex,
            direction: 'asc',
          }
        }
      }

      this.changeSort(sortData)
      // this.sendRequest()

      return items;
    },
    toCamelCase(str) {
      return camelCase(str)
    },
    changePagination(index) {
      this.changeSkip(this.limit * (index - 1))
      this.$vuetify.goTo(0)
      this.fetchList(this.$route.params)
    },
    changeGenerateAllDialog() {
      this.showGenerateAllDialog = true
    },
    generateAllCertificates() {
      this.generateAll({ courseId: this.$route.params.courseId }).then(() => {
        this.showGenerateAllDialog = false
        this.$toasted.success('Certificates/diplomas is on generating')

        let refresh = setInterval(
          () => {
            if (this.$route.name !== 'user-certificate-course') {
              clearInterval(refresh)
              return
            }
            this.fetchList(this.$route.params)
          },
          3000
        )
      })
    },
    changeGenerateUserDialog(item) {
      this.showGenerateUserDialog = true
      this.currentUserCourse = item
    },
    generateUserCertificate() {
      this.generateUser({ courseId: this.currentUserCourse.courseId, userId: this.currentUserCourse.userId })
        .then(() => {
          this.showGenerateUserDialog = false
          this.currentUserCourse = null
          this.$toasted.success('Certificate/diploma is successfully generated')
        }).catch(() => {
          this.showGenerateUserDialog = false
          this.currentUserCourse = null
          this.$toasted.error('Certificate/diploma is not generated. Conditions for obtaining a certificate/diploma are not met')
        })
    },
    changeRemoveUserDialog(item) {
      this.showRemoveUserDialog = true
      this.currentUserCourse = item
    },
    removeUserCertificate() {
      this.removeUser({ courseId: this.currentUserCourse.courseId, userId: this.currentUserCourse.userId, userCertificateId: this.currentUserCourse.userCertificate.data.id }).then(() => {
        this.showRemoveUserDialog = false
        this.currentUserCourse = null
        this.$toasted.success('Certificate/diploma is successfully deleted')
      })
    },
    updateFilter(params) {
      this.filter = params
    },
    onChangeFilter() {
      this.page = 1
    },
    sendRequest: debounce(function () {
      this.fetchList(this.$route.params)
    }, 1000),
    getLocalStorageDataFilter() {
      const courseId = +this.$route.params.courseId
      this.setFilterCourseId(+this.getLocalStorageFilterCourseId)
      if (!!this.filterCourseId && this.filterCourseId === courseId) {
        this.setFilterData(this.getLocalStorageFilter)
      } else {
        localStorage.removeItem('user_certificate_filters')
        localStorage.removeItem('user_certificate_filter_course_id')
      }
    }
  },
  destroyed() {
    this.setListContent([])
    this.setList([])
  }
}
