import { mapActions, mapGetters, mapMutations } from 'vuex'
import { debounce } from 'lodash'
import validation from '@/mixins/validation'
import mixins from '@/mixins'
import { DEFAULT_FILTERS } from '@/modules/reviews/constants'

export default {
  name: 'filter-user-certificate',
  mixin: [mixins, validation],
  data () {
    return {
      search: '',
      searchInput: '',
      filter: DEFAULT_FILTERS
    }
  },
  computed: {
    ...mapGetters({
      setting: 'courses/setting',
      filters: 'userCertificate/filters',

      packages: 'coursesPackages/listSimple',
      packagesLoading: 'coursesPackages/listSimpleLoading',

      certificates: 'coursesCertificates/listSimple',
      certificatesLoading: 'coursesCertificates/isListSimpleLoading',

      basicUsers: 'user/basicUsers',
      basicUsersLoading: 'user/basicUsersLoading',
    }),
    getLocalStorageFilter () {
      return JSON.parse(localStorage.getItem('user_certificate_filters'))
    },
    getLocalStorageSearch () {
      return JSON.parse(localStorage.getItem('user_certificate_search'))
    },
    getLocalStorageFilterCourseId () {
      return localStorage.getItem('user_certificate_filter_course_id')
    }
  },
  created () {
    this.setFilter()
    this.getLocalStorageDataFilter()

    this.fetchPackagesList(this.$route.params)
    this.fetchCertificatesList(this.$route.params)
  },
  methods: {
    ...mapActions({
      fetchList: 'userCertificate/GET_LIST',
      fetchPackagesList: 'coursesPackages/PACKAGES_GET_SIMPLE_LIST',
      fetchCertificatesList: 'coursesCertificates/CERTIFICATES_GET_SIMPLE_LIST',
      fetchUsersBasic: 'user/GET_BASIC_USERS',
    }),
    ...mapMutations({
      changeFilter: 'userCertificate/CHANGE_FILTER',
      setFilterCourseId: 'userCertificate/setFilterCourseId',
      setFilterData: 'userCertificate/setFilterData'
    }),
    sendRequest: debounce(function () {
      this.fetchList(this.filter)
    }, 500),
    setFilter () {
      const courseId = +this.$route.params.courseId
      if (!!this.filterCourseId && this.filterCourseId === courseId) {
        Object.assign(this.filter, this.filters)
      }
    },
    changeInputUsers: debounce(function (val) {
      if (!val) return
      if (this.search?.full_name !== val) {
        this.fetchUsersBasic({
          keywords: val
        })
      }
    }, 500),
    changeFilterLocal (val, type) {
      this.setFilterCourseId(+this.$route.params.courseId)
      if (typeof val === 'undefined') val = ''
      this.changeFilter({
        value: val,
        type: type
      })
      localStorage.setItem('user_certificate_filters', JSON.stringify(this.filters))
      localStorage.setItem('user_certificate_filter_course_id', +this.$route.params.courseId)
      this.fetchList(this.$route.params)
      this.$emit('change')
    },
    autoCompleteChanged (e, type) {
      if (!e) {
        this.filter.user = ''
        this.search = ''
      } else {
        this.filter.user = e.id
        this.search = e
      }
      this.changeFilter({
        value: e.hasOwnProperty('id') ? e.id : '',
        type: type
      })
      this.fetchList(this.$route.params)
      localStorage.setItem('user_certificate_filters', JSON.stringify(this.filters))
      localStorage.setItem('user_certificate_filter_course_id', +this.$route.params.courseId)
    },
    filterUsersAutoComplete (item, queryText) {
      return item.full_name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 || item.email.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 || item.id.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
    },
    handleResetFilter () {
      this.changeFilter(null)
      for (const i in this.filter) {
        this.filter[i] = ''
      }
      this.search = ''
      this.fetchList(this.$route.params)
      this.$emit('change')
    },
    getLocalStorageDataFilter () {
      const courseId = +this.$route.params.courseId
      this.setFilterCourseId(+this.getLocalStorageFilterCourseId)
      if (!!this.filterCourseId && this.filterCourseId === courseId) {
        this.setFilterData(this.getLocalStorageFilter)
        Object.assign(this.filter, this.filters)
      } else {
        localStorage.removeItem('user_certificate_filters')
        localStorage.removeItem('user_certificate_filter_course_id')
      }
    }
  }
}
